let API_URL = `https://blog.talentrocks.ru/ghost/api/v4/content/posts/?key=52e18543c3958914160c0f134f&include=tags&limit=3`;

if (window.ghostBlogPostsTagFilter) API_URL = `${API_URL}&filter=tag:${window.ghostBlogPostsTagFilter}`;

const BLOG_SECTION = '.blog_section';
const POSTS_SECTION = '.blog_section__inner';

fetch(API_URL)
  .then((response) => {
    if (!response.ok) {
      document.querySelector(BLOG_SECTION).remove();
    }
    response.json().then((data) => {
      const posts = data.posts;
      let html = ``;
      if (posts) {
        posts.forEach((el, key) => {
          if (key < 3) {
            if (el.url && el.feature_image && el.tags[0] && el.tags[0].name && el.title) {
              html += `
            <a class='blog_section__inner__item' href='${el.url}' target='_blank'>
              <div class='blog_section__inner__item__img'>
                <img src='${el.feature_image}' alt='article image'/>
              </div>
              <div class='blog_section__inner__item__content'>
               <span>${el.tags[0].name}</span>
                <div class='blog_section__inner__item__content__title'> ${el.title} </div>
              </div>
            </a>
          `;
            }
          }
        });
        if (!html) document.querySelector(BLOG_SECTION).remove();
        else document.querySelector(POSTS_SECTION).innerHTML = html;
      } else {
        document.querySelector(BLOG_SECTION).remove();
      }
    });
  })
  .catch((error) => {
    document.querySelector(BLOG_SECTION).remove();
    console.log(error);
  });
